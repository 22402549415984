// @flow
import * as React from 'react';
import styled from 'styled-components';
import { withPrefix } from 'gatsby';

import { media } from '../../utils/style-utils';

const FooterWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  padding: 120px 16px 24px;
  justify-content: flex-start;
  align-items: flex-end;
  color: ${({ color }) => color};
  background: ${({ backgroundColor }) => backgroundColor};
  font-size: 10px;
  text-align: center;

  ${media.tablet`    
    justify-content: center;
    font-size: 12px;
    padding: 24px 16px;
  `};
`;

const CopyRight = styled.p`
  margin-bottom: 8px;
`;

const A = styled.a`
  cursor: pointer;
  opacity: 0.6;
  margin-top: 12px;
  text-decoration: none;
  color: ${({ color }) => color};
  transition: opacity 0.2s ease-in;

  &:hover {
    opacity: 1;
  }
`;

const FARLogo = styled.div`
  background: ${({ colorVersion }) =>
    `url(${withPrefix(`./logo/far${colorVersion ? '_color' : ''}.svg`)})`};

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 50px;
  height: 38px;
  margin-left: auto;
  position: absolute;
  left: 16px;
  bottom: 84px;

  ${media.tablet`  
    bottom: 16px;
    width: 50px;
    height: 38px;
  `};

  ${media.hd`  
    width: 62px;
    height: 58px;
  `};
`;

type Props = {
  color?: string,
  backgroundColor?: string,
};

const Footer = ({ color = '#000', backgroundColor = '#fff' }: Props) => {
  const year = new Date().getFullYear();
  return (
    <FooterWrapper color={color} backgroundColor={backgroundColor}>
      <a href="https://www.far.se/" target="_blank" id="test-id-map-link">
        {' '}
        <FARLogo colorVersion={color === '#000'} />
      </a>

      <div>
        <CopyRight>
          &copy;
          {` copyright Mcon AB ${year}`}
        </CopyRight>
        <A
          href="https://hsc.studio"
          target="_blank"
          rel="noopener"
          color={color}
        >
          made by hsc studio
        </A>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
